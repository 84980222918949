import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { makeStyles } from "@mui/styles";

const ContactDetails = ({ submitError, setPaxName }) => {
  const classes = useStyle();
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [name, setName] = useState("");
  const [phoneCode, setPhoneCode] = useState("62");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.customerContactDetails = {};
    if (name !== "") {
      KAI.customerContactDetails.name = name;
    } else {
      KAI.customerContactDetails.name = "";
    }

    if (phoneCode !== "") {
      KAI.customerContactDetails.phoneCode = phoneCode;
    } else {
      KAI.customerContactDetails.phoneCode = "";
    }

    if (phoneNo !== "" && phoneNo.length >= 7 && phoneNo > 0) {
      KAI.customerContactDetails.phoneNo = phoneNo;
    } else {
      KAI.customerContactDetails.phoneNo = "";
    }

    /* eslint-disable-next-line */
    if (email !== "" && email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      KAI.customerContactDetails.email = email;
    } else {
      KAI.customerContactDetails.email = "";
    }
    localStorage.setItem("KAI", JSON.stringify(KAI));

    if (
      name !== "" &&
      phoneCode !== "" &&
      phoneCode > 0 &&
      phoneNo !== "" &&
      phoneNo.length >= 7 &&
      phoneNo > 0 &&
      email !== "" &&
      /* eslint-disable-next-line */
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setOpenContactDetails(false);
      setPaxName(false);
    } else setError(true);
  };

  useEffect(() => {
    if (submitError) {
      if (
        name === "" ||
        phoneCode === "" ||
        phoneNo.length < 7 ||
        phoneNo < 0 ||
        phoneNo === "" ||
        email === "" ||
        /* eslint-disable-next-line */
        !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        setError(true);
        setOpenContactDetails(true);
      }
    }
  }, [submitError, email, name, phoneCode, phoneNo]);

  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      resolve(JSON.parse(localStorage.getItem("KAI")));
    });
    p.then((res) => {
      if (res?.customerContactDetails) {
        setName(res.customerContactDetails.name);
        setPhoneCode(res.customerContactDetails.phoneCode);
        setPhoneNo(res.customerContactDetails.phoneNo);
        setEmail(res.customerContactDetails.email);
      }
    });
  }, []);

  const setContactPersonAsUser = () => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.passengerList[0].name = name;
    setPaxName(true);
    localStorage.setItem("KAI", JSON.stringify(KAI));
  };

  return (
    <div id={"contact-details"} style={{ marginTop: "24px" }}>
      <Typography
        children={"Data Pemesan (Untuk E - Ticket)"}
        className={"inputSectionLabels"}
      />

      <Accordion
        expanded={openContactDetails}
        onChange={() => setOpenContactDetails(!openContactDetails)}
        id={"dataPemesan-dropdown"}
        style={{ marginTop: "16px" }}
        className={"dropShadow"}
      >
        <AccordionSummary>
          {name === "" ||
          phoneCode === "" ||
          phoneNo === "" ||
          email === "" ||
          openContactDetails ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography className={"inputSecondarySectionLabels"}>
                Isi Data Pemesan
                <span style={{ color: "red", fontSize: "bold" }}>*</span>
              </Typography>
              <Typography
                children={"+ Isi Data"}
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#DD6B00",
                  display: openContactDetails ? "none" : "",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography
                  children={name}
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
                <Typography
                  children={email}
                  style={{ fontSize: "12px", color: "#666", marginTop: "5px" }}
                />
                <Typography
                  children={`+${phoneCode} ${phoneNo}`}
                  style={{ fontSize: "12px", color: "#666" }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <EditRoundedIcon
                  style={{
                    fontSize: "16px",
                    marginRight: "2px",
                    color: "#DD6B00",
                  }}
                />
              </div>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0" }}>
          <Divider style={{ marginBottom: "16px" }} />
          <Typography
            children={"Nama Lengkap"}
            style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }}
          />

          <TextField
            id={"namaLengkap-value"}
            fullWidth
            autoComplete={"off"}
            value={name}
            error={error && name === ""}
            onChange={(e) => {
              if (
                e.target.value.length <= 25 &&
                /^[a-zA-Z ]*$/.test(e.target.value)
              ) {
                setName(e.target.value);
              }
            }}
            FormHelperTextProps={{
              style: {
                margin: "0",
                color: "#c21616",
                marginBottom: "24px",
                display: error ? "" : "none",
              },
            }}
            helperText={
              error && name === ""
                ? "Mohon untuk tidak memasukkan tanda baca"
                : ""
            }
            InputProps={{
              style: { height: "40px", borderRadius: "8px", margin: "8px 0" },
            }}
          />
          <Typography
            children={"Sesuai KTP/paspor/SIM (tanpa tanda baca atau gelar)"}
            style={{
              fontSize: "11px",
              color: "#979797",
              marginBottom: "24px",
              display: error && name === "" ? "none" : "",
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                children={"Kode Negara"}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#515151",
                }}
              />

              <TextField
                id={"kodeNegara-value"}
                fullWidth
                autoComplete={"off"}
                className={classes.telInput}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type={"number"}
                InputProps={{
                  style: {
                    height: "40px",
                    borderRadius: "8px",
                    margin: "8px 0",
                  },
                  startAdornment: <AddIcon fontSize={"small"} />,
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "0",
                    color: "#c21616",
                    display: error ? "" : "none",
                  },
                }}
                error={error && (phoneCode === "" || phoneCode < 0)}
                helperText={
                  (phoneCode === "" || phoneCode < 0) && error
                    ? "Pilih Kode Negara"
                    : ""
                }
                value={phoneCode}
                onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    setPhoneCode(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <Typography
                children={"No. Handphone"}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#515151",
                }}
              />

              <TextField
                autoComplete={"off"}
                fullWidth
                className={classes.telInput}
                id={"handphone-value"}
                error={
                  error && (phoneNo < 0 || phoneNo === "" || phoneNo.length < 7)
                }
                value={phoneNo}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type={"number"}
                onChange={(e) => {
                  if (e.target.value.length <= 14) {
                    setPhoneNo(e.target.value);
                  }
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "0",
                    color: "#c21616",
                    display: error ? "" : "none",
                  },
                }}
                helperText={
                  (phoneNo === "" || phoneNo < 0 || phoneNo.length < 7) && error
                    ? "No. Handphone tidak terdaftar"
                    : ""
                }
                InputProps={{
                  style: {
                    height: "40px",
                    borderRadius: "8px",
                    margin: "8px 0",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Typography
            children={"Email"}
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "#515151",
              marginTop: "12px",
            }}
          />
          <TextField
            autoComplete={"off"}
            fullWidth
            id={"email"}
            /* eslint-disable-next-line */
            error={(error && email === "") || (error && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: { height: "40px", borderRadius: "8px", margin: "8px 0" },
            }}
            FormHelperTextProps={{
              style: {
                margin: "0",
                color: "#c21616",
                display: error ? "" : "none",
              },
            }}
            helperText={
              /* eslint-disable-next-line */
              (email === "" && error) || (error && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
                ? "Email tidak terdaftar"
                : ""
            }
          />
          <Typography
            children={"Contoh: email@example.com"}
            style={{
              fontSize: "11px",
              color: "#979797",
              display:
              /* eslint-disable-next-line */
                (email === "" && error) || (error && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
                  ? "none"
                  : "",
            }}
          />

          <Button
            id={'submitContactInfo'}
            children={"Simpan"}
            className={"submitOrangeButton"}
            fullWidth
            onClick={() => handleSubmit()}
          />
        </AccordionDetails>
      </Accordion>
      {name && !openContactDetails && (
        <div className="addContactInfoBox">
          <Typography
            style={{
              fontWeight: "400px",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#615A5A",
            }}
          >
            Detail tiket akan dikirimkan ke email pemesan. Pastikan email anda
            benar
          </Typography>
        </div>
      )}
      {name && !openContactDetails && (
        <Button
          id={'addSameNameToDetails'}
          children={"Tambah Sebagai Penumpang"}
          className={"submitOrangeButtonLight"}
          fullWidth
          onClick={() => {
            setContactPersonAsUser();
          }}
        />
      )}
    </div>
  );
};

export default ContactDetails;

const useStyle = makeStyles({
  telInput: {
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
});
