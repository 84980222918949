import React, { useState } from "react";
import { Button } from "@mui/material";
import { getWagonClass } from "../../common/Utils";
import { changeSeatAPI } from "../../api/ApiUtils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonComponents/Loader";
import Footer from "../Footer/Footer";

const SubmitSeat = () => {
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getNewStamFormSeatIdNew = (list, newSelectedSeat) => {
    for (let i = 0; i < list.length; i++) {
      const className = Object.keys(list[i])[0].split(",")[0];
      const seatNumber = Object.keys(list[i])[0].split(",")[1];
      const seatClass =
        getWagonClass(className.split("-")[0], className.split("-")[1]) +
        "," +
        seatNumber;
      if (seatClass === newSelectedSeat) {
        const selectedSeatDetails = list[i][`${className},${seatNumber}`];
        return selectedSeatDetails.stamformseatid;
      }
    }
    return null;
  };

  const setUpdatedSeat = (
    data,
    KAI,
    oldSeatStamformseatid,
    newSeatStamformseatid
  ) => {
    const { ticketBookingResponse } = KAI;
    ticketBookingResponse.data.paxes.map((passenger, i) => {
      if (passenger.stamformseatid === oldSeatStamformseatid) {
        ticketBookingResponse.data.paxes[i].seat = data[0].seat;
        ticketBookingResponse.data.paxes[i].ticketnum = data[0].ticketnum;
        ticketBookingResponse.data.paxes[i].stamformdetcode =
          data[0].stamformdetcode;
        ticketBookingResponse.data.paxes[i].stamformseatid =
          newSeatStamformseatid;
        ticketBookingResponse.data.paxes[i].wagondetrow = data[0].wagondetrow;
        ticketBookingResponse.data.paxes[i].wagondetcol = data[0].wagondetcol;
        return 0;
      }
      return 0;
    });
    KAI.ticketBookingResponse = ticketBookingResponse;
    localStorage.setItem("KAI", JSON.stringify(KAI));
  };

  const handleSubmit = () => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    const newSelectedSeat = KAI.seatList.newSelectedSeat;
    const originalPaxSeats = KAI.seatList.originalPaxSeats;

    // if same default is selected..... ===> defaultSeat !== newSelectedSeat
    let isSeatChanged = false;
    let reqBodyArray = [];
    let promises = [];
    Object.keys(newSelectedSeat).map(async (key, index) => {
      if (originalPaxSeats[key] !== newSelectedSeat[key]) {
        isSeatChanged = true;
        const seatListArray = KAI.seatList.list.data;

        const body = {
          destinationId: KAI.ticketDetails.destinationid,
          originId: KAI.ticketDetails.originid,
          subClass: KAI.ticketBookingResponse.data.subclass,
          tripId: KAI.ticketBookingResponse.data.tripid + "",
          wagonClassId: KAI.ticketBookingResponse.data.wagonclassid,
          bookingCode: KAI.ticketBookingResponse.data.bookingcode,
          noka: KAI.ticketBookingResponse.data.noka,
          stasiunOrginCode: KAI.ticketBookingResponse.data.stasiunorigincode,
          stasiunDestCode: KAI.ticketBookingResponse.data.stasiundestcode,
          wagonClassCode: KAI.ticketBookingResponse.data.wagonclasscode,
          seats: [
            {
              passengerType: KAI.seatList.paxes[key].passengertype,
              stamFormSeatIdOld: KAI.seatList.paxes[key].stamformseatid,
              stamFormSeatIdNew: getNewStamFormSeatIdNew(
                seatListArray,
                newSelectedSeat[key]
              ),
              ticketNum: KAI.seatList.paxes[key].ticketnum,
            },
          ],
        };

        reqBodyArray.push(body);
        setLoading(true);

        promises.push(changeSeatAPI(body));
      }
    });

    if (!isSeatChanged) {
      navigate("/verefiedinputdata");
    }

    Promise.allSettled(promises)
      .then((results) => {
        let hasError = { error: false, message: "" };
        results.forEach((item, index) => {
          console.log(item);
          if (item.value.data.success) {
            setUpdatedSeat(
              item.value.data.data,
              KAI,
              reqBodyArray[index].seats[0].stamFormSeatIdOld,
              reqBodyArray[index].seats[0].stamFormSeatIdNew
            );
          } else {
            hasError = { error: true, message: item.value.data.message };
          }
          if (index === results.length - 1) {
            if (hasError.error) {
              setLoading(false);
              throw new Error(hasError.message);
            } else {
              setLoading(false);
              navigate("/verefiedinputdata");
            }
          }
        });
      })
      .catch((err) => {
        showErrorPopUp({
          ...errorPopUp,
          showPopUp: true,
          message: err.message,
        });
        console.log("error = ", err);
      });
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
    navigate("/verefiedinputdata");
  };

  return (
    <div className={"submitSeatContainer"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ padding: "16px 24px" }}>
        <Button
          id="Selsai"
          children={"Simpan"}
          className={"seatOrangeButton"}
          fullWidth
          onClick={() => handleSubmit()}
        />
      </div>
      <Footer />
    </div>
  );
};

export default SubmitSeat;
