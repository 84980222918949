import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { popularStationList } from "../../../common/PopularStationList";

const SelectStationDropDown = ({
  list,
  slideOpen,
  setOpenStationDropDown,
  setStationName,
  title,
}) => {
  const [name, setName] = useState("");
  const [stationArray, setStation] = useState([]);
  const [selectedStation, setSelectedStation] = useState();

  useEffect(() => {
    if (name !== "") {
      let sortedStationList = [];
      for (let i = 0; i < list.length; i++) {
        const isStationName = list[i].stationname
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        const isStationCode = list[i].stationcode
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        if (isStationName || isStationCode) {
          sortedStationList.push(list[i]);
        }
      }
      setStation([...sortedStationList]);
      sortedStationList = [];
    }
    if (name === "") {
      setStation([...popularStationList]);
    }
  }, [name, list]);

  useEffect(() => {
    if (selectedStation) {
      setStationName(selectedStation);
      setOpenStationDropDown(false);
    }
  }, [selectedStation, setStationName, setOpenStationDropDown]);

  return (
    <div
      className={"stationListContainer"}
      style={{ display: slideOpen ? "" : "none" }}
    >
      <Slide direction={"up"} in={slideOpen} id="slider-container">
        <div className={"stationListBlock"}>
          <div className={"sliderStationList"} id="slider-container">
            <Paper id="slider-paper" style={{ boxShadow: "none" }}>
              <div style={{ padding: "0 16px 16px 16px" }}>
                <div className={"selectStationSliderHeader"}>
                  <Typography
                    id={'stationListType'}
                    children={title}
                    className="headerText"
                  />
                  <IconButton
                    id={'closeStationList'}
                    onClick={() => {
                      setName("");
                      setOpenStationDropDown(false);
                    }}
                  >
                    <CloseIcon style={{ color: "#ADA6AE" }} />
                  </IconButton>
                </div>
                <TextField
                  id="station-name-input"
                  fullWidth
                  placeholder="Cari..."
                  onChange={(e) => setName(e.target.value)}
                  autoComplete={"off"}
                  value={name}
                  inputProps={{ style: { fontWeight: "800" } }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "100px",
                      background: "#F7F6F6",
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#ADA6AE" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                {name === "" && (
                  <div style={{ marginTop: "15px" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#6A6C6D",
                        fontWeight: "bold",
                      }}
                    >
                      Stasiun Populer
                    </Typography>
                  </div>
                )}
                <div className={"stationlistDropdown"}>
                  {stationArray.map(
                    ({ stationname, stationcode, id }, i) => (
                      <div
                        id={'stationNameBlock-'+id}
                        key={id}
                        className={"stationNameBlock"}
                        onClick={() => setSelectedStation(stationArray[i])}
                      >
                        <Typography
                          id="stationName"
                          children={stationname}
                          className={"stationName"}
                        />
                        <Typography
                          id="stationCode"
                          children={stationcode}
                          className={"stationStatusHomepage"}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default SelectStationDropDown;
