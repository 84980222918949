import React, { useEffect, useState } from "react";
import "../Homepage.css";
import HomepageForm from "./HomepageForm";
import { Footer } from "../../index";
import PassengerDialog from "./PassengerContainer/PassengerDialog";
import { getStation } from "../../../api/ApiUtils";
import SelectStationDropDown from "./SelectStationDropDown";
import Loader from "../../CommonComponents/Loader";
import { PopUpModal } from "../../CommonComponents/PopUp/PopUpModal";
import { popularStationList } from "../../../common/PopularStationList";
const ImageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export const PassengerContext = React.createContext();

const Container = () => {
  const [openPassengerDialog, setOpenPassengerDialog] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState({
    adult: 1,
    child: 0,
  });

  const [departureDate, setDepartureDate] = useState(new Date());
  const [stationList, setStationList] = useState([]);
  const [openStartStationDropDown, setOpenStartStationDropDown] =
    useState(false);
  const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);
  const [startStation, setStartStation] = useState("");
  const [stopStation, setStopStation] = useState("");
  const [kaiImageHeight, setKaiImageHeight] = useState(0);
  const [formContainerTop, setFormContainerTop] = useState(0);

  useEffect(() => {
    if (kaiImageHeight) {
      const calculatedHeight = kaiImageHeight - kaiImageHeight * 0.2;
      // SET height for the popup container to overlap on the KAI background image
      console.log(
        "calculatedHeight => ",
        calculatedHeight,
        kaiImageHeight,
        kaiImageHeight * 0.185
      );
      setFormContainerTop(kaiImageHeight * 0.185);

      // SET height for entire container after which we can display FOOTER
      // const containerElementHeight = document.getElementById('formContainer').offsetHeight;
      // setContainerHeight(containerElementHeight + calculatedHeight + 28);

      // GET HEIGHT OF THE DEVICE
      // const deviceHeight = window.innerHeight
      // || document.documentElement.clientHeight
      // || document.body.clientHeight;

      // SET height of device if device height is less than our container ---this is done to handle the footer at the end else footer gets overlapsed by the popup container
      // if(deviceHeight < containerElementHeight + calculatedHeight + 28)
      // setScreenHeight(deviceHeight)
    }
  }, [kaiImageHeight]);

  const [loading, setLoading] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  useEffect(() => {
    setLoading(true);
    localStorage.removeItem("KAI");
    const KAI = {};
    localStorage.setItem("KAI", JSON.stringify(KAI));

    // TO AVOID THE WARNING CREATED A SEPARATE FUNCTION
    async function getStationData() {
      const response = await getStation();
      if (response.success) {
        let tempStationList = response.data;
        const stationCodeMap = tempStationList.map((item) => item.stationcode);
        popularStationList.forEach(function (item) {
          if (!stationCodeMap.includes(item.stationcode)) {
            tempStationList.push(item);
          }
        });
        setStationList(tempStationList);
        setLoading(false);
      } else {
        showErrorPopUp({
          showPopUp: true,
          message: response.message,
        });
        setLoading(false);
      }
    }
    getStationData();
  }, []);

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id="Homepage-Container">
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />

      <div id="Container" className={"Container"}>
        <img
          alt="Homepage-Background"
          id="Homepage-Background"
          src={ImageBaseUrl + "/HomepageBackground.svg"}
          width="100%"
          onLoad={() =>
            setKaiImageHeight(
              document.getElementById("Homepage-Background").offsetHeight
            )
          }
        />
      </div>
      <div
        style={{
          position: "relative",
          bottom: `${formContainerTop}px`,
          padding: "0 16px",
        }}
      >
        <PassengerContext.Provider
          value={{
            openPassengerDialog,
            setOpenPassengerDialog,
            passengerInfo,
            setPassengerInfo,
            startStation,
            stopStation,
            setStartStation,
            setStopStation,
            setOpenStartStationDropDown,
            setOpenStopStationDropDown,
            departureDate,
            setDepartureDate,
            setLoading,
            showErrorPopUp,
          }}
        >
          <HomepageForm kaiImageHeight={formContainerTop} />
        </PassengerContext.Provider>
      </div>
      <Footer />
      <PassengerDialog
        openPassengerDialog={openPassengerDialog}
        setOpenPassengerDialog={setOpenPassengerDialog}
        setPassengerInfo={setPassengerInfo}
      />
      <SelectStationDropDown
        list={stationList}
        slideOpen={openStartStationDropDown}
        setOpenStationDropDown={setOpenStartStationDropDown}
        setStationName={setStartStation}
        title={"Pilih Stasiun Keberangkatan"}
      />
      <SelectStationDropDown
        list={stationList}
        slideOpen={openStopStationDropDown}
        setOpenStationDropDown={setOpenStopStationDropDown}
        setStationName={setStopStation}
        title={"Pilih Stasiun Tujuan"}
      />
    </div>
  );
};

export default Container;
