import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";
import { getWagonClass } from "../../../common/Utils";
import changePage from "./SeatSlideChange";

const OneByOne = ({
  selectedClass,
  setSelectedClass,
  setSeatChange,
  currentSelectedClassLayout,
}) => {
  const [classSeatArray, setClassSeatArray] = useState([]);
  const [passengerList, setPassengerList] = useState([]);
  const [currentPassengerIndex, setCurrentPassengerIndex] = useState("");
  const [noOfRows, setNoOfRows] = useState({});
  const [height, setHeight] = useState("100px");
  const classes = useStyle();

  useEffect(() => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    const currentTicketNumber = KAI.seatList.currentSeat.ticketnum;
    const passengerListArray = [];

    // CREATE LIST OF PASSENGERS DEFAULT ALLOCATED SEAT...... TO PLACE NUMBERS ON SEAT BLOCK BY COMPARING
    KAI.seatList.paxes.map(
      ({ stamformdetcode, wagondetrow, wagondetcol, ticketnum }, i) => {
        const wagonClassName = getWagonClass(
          stamformdetcode.split("-")[0],
          stamformdetcode.split("-")[1]
        );
        passengerListArray.push(
          `${wagonClassName},${wagondetrow}${wagondetcol}`
        );

        // SET THE INDEX OF SELECTED PASSENGER TO PLACE ON SEAT BLOCK
        if (currentTicketNumber === ticketnum) {
          setCurrentPassengerIndex(i);
        }
        return 0;
      }
    );

    KAI.seatList.originalPaxSeats = passengerListArray;
    localStorage.setItem("KAI", JSON.stringify(KAI));

    const changedSeatList = passengerListArray.map((paxSeat, index) => {
      if (KAI?.seatList?.newSelectedSeat[index]) {
        return KAI?.seatList?.newSelectedSeat[index];
      }
      return paxSeat;
    });

    setPassengerList(changedSeatList);
    // SETS NO OF PAGES TO SWIPE/CHANGE
    setClassSeatArray(KAI.seatList.list.classSeatData);
  }, []);

  useEffect(() => {
    if (currentSelectedClassLayout) {
      setHeight(currentSelectedClassLayout.rows * (32 + 24) - 12); // height + marginBottom of each row

      const rows = [];
      for (let index = 0; index < currentSelectedClassLayout.rows; index++) {
        rows.push(index);
      }
      const available = currentSelectedClassLayout.classSeatAvailable;
      const notAvailable = currentSelectedClassLayout.classSeatUnAvailable;
      setNoOfRows({ rows, available, notAvailable });
    }
  }, [currentSelectedClassLayout]);

  return (
    <Carousel
      autoPlay={false}
      swipe={false}
      navButtonsAlwaysVisible={true}
      cycleNavigation={false}
      navButtonsWrapperProps={{
        style: {
          display: "flex",
          justifyContent: "center",
          margin: "20px",
        },
      }}
      navButtonsProps={{ className: classes.carouselNavButtons }}
      indicatorContainerProps={{
        style: { marginTop: height, visibility: "hidden" },
      }}
      className={"carousel"}
      next={(next, active) => {
        if (active || next) changePage("next", selectedClass, setSelectedClass);
      }}
      prev={(prev, active) => {
        if (active || prev) changePage("prev", selectedClass, setSelectedClass);
      }}
    >
      {classSeatArray.map((v, i) => (
        <div key={i}>
          <RowSeat
            selectedClass={selectedClass}
            passengerList={passengerList}
            setPassengerList={setPassengerList}
            currentPassengerIndex={currentPassengerIndex}
            setSeatChange={setSeatChange}
            noOfRows={noOfRows}
          />
        </div>
      ))}
    </Carousel>
  );
};

const useStyle = makeStyles({
  carouselNavButtons: {
    padding: "5px",
    backgroundColor: "#007DFE",
    position: "fixed",
    margin: "5px auto",
    "&:hover": {
      opacity: "1!important",
      backgroundColor: "#007DFE !important",
      filter: "brightness(100%) !important",
    },
  },
});

const RowSeat = ({
  selectedClass,
  passengerList,
  setPassengerList,
  currentPassengerIndex,
  setSeatChange,
  noOfRows,
}) => {
  const getPassengerIndex = (seat) => {
    let index;
    for (index = 0; index < passengerList.length; index++) {
      if (passengerList[index] === seat) {
        return `${index + 1}`;
      }
    }
    return "";
  };

  const getBackground = (
    classSeatAvailableList,
    classSeatNotAvailableList,
    rowColumn,
    seat
  ) => {
    // IF THE SEAT => ROW+COLUMN IS INCLUDED IN SEAT UNAVAILABLE RETURN WHITE TO DISPLAY AS BLANK
    if (classSeatNotAvailableList.includes(rowColumn)) return "#FFF";

    // IF SEAT NOT IN UNAVAILABLE SEAT BUT EXISTS IN SELECTED PASSENGER SEAT LIST SEND WHITE TO PUT BORDER AND NUMBER
    if (passengerList.includes(seat)) return "#007DFE";

    // IF SEAT AVAILABLE TO BOOK
    if (classSeatAvailableList.includes(rowColumn)) return "#FFF";
    else return "#B7B7B7";
  };

  const getBorder = (classSeatAvailableList, rowColumn, seat) => {
    // IF THE SEAT IS SAME AS ELEMENT PRESENT IN PASSENGER LIST RETURN BORDER
    if (
      classSeatAvailableList.includes(rowColumn) &&
      !passengerList.includes(seat)
    )
      return "1px solid #1F1F1F";
    return "0px solid #FFF";
  };

  const isSeatOccupiedByChange = (seat, passengerIndex) => {
    let isSeatOccupiedByPax = false;
    passengerList.forEach((element, index) => {
      if (passengerIndex !== index && element === seat) {
        isSeatOccupiedByPax = true;
      }
    });
    return isSeatOccupiedByPax;
  };

  const handleSeatClick = (seat, passengerIndex, classSeatAvailableList) => {
    if (classSeatAvailableList.includes(seat)) {
      const KAI = JSON.parse(localStorage.getItem("KAI"));
      let list = [...passengerList];
      let existingSeat = list[passengerIndex].split(",")[0];
      if (existingSeat !== selectedClass) existingSeat = selectedClass;
      list[passengerIndex] = existingSeat + "," + seat;
      if (isSeatOccupiedByChange(list[passengerIndex], passengerIndex)) {
        return;
      }
      setPassengerList(list);
      let newSelectedSeat = {};
      newSelectedSeat[passengerIndex] = list[passengerIndex];
      KAI.seatList.newSelectedSeat = {
        ...KAI?.seatList?.newSelectedSeat,
        ...newSelectedSeat,
      };
      localStorage.setItem("KAI", JSON.stringify(KAI));

      setSeatChange(true);
    }
  };

  return (
    <div>
      <RowIndex />
      {noOfRows?.rows?.map((data, i) => {
        const classSeatAvailableList = noOfRows.available;
        const classSeatNotAvailableList = noOfRows.notAvailable;
        const length = noOfRows.rows.length * 2;
        const SEAT_ROW = data + 1;
        const isVisibleA = i * 2 + 1 <= length;
        const isVisibleB = i * 2 + 2 <= length;

        return (
          <div id={data} key={data}>
            <Grid
              container
              justifyContent={"center"}
              style={{ marginBottom: "24px" }}
            >
              <Grid
                item
                style={{
                  height: "32px",
                  width: "32px",
                  padding: 5,
                  marginRight: 10,
                  textAlign: "center",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data + 1}
              </Grid>

              <Grid
                id={SEAT_ROW + "A"}
                item
                className={"seatBox"}
                style={{
                  color: "#FFF",
                  visibility: isVisibleA ? "" : "hidden",
                  background: getBackground(
                    classSeatAvailableList,
                    classSeatNotAvailableList,
                    SEAT_ROW + "A",
                    selectedClass + "," + SEAT_ROW + "A"
                  ),
                  border: getBorder(
                    classSeatAvailableList,
                    SEAT_ROW + "A",
                    selectedClass + "," + SEAT_ROW + "A"
                  ),
                }}
                onClick={() =>
                  handleSeatClick(
                    SEAT_ROW + "A",
                    currentPassengerIndex,
                    classSeatAvailableList
                  )
                }
              >
                {getPassengerIndex(selectedClass + "," + SEAT_ROW + "A")}
              </Grid>

              <Grid item style={{ height: "32px", width: "22px" }}>
                {" "}
              </Grid>

              <Grid
                id={SEAT_ROW + "B"}
                item
                className={"seatBox"}
                style={{
                  color: "#FFF",
                  visibility: isVisibleB ? "" : "hidden",
                  background: getBackground(
                    classSeatAvailableList,
                    classSeatNotAvailableList,
                    SEAT_ROW + "B",
                    selectedClass + "," + SEAT_ROW + "B"
                  ),
                  border: getBorder(
                    classSeatAvailableList,
                    SEAT_ROW + "B",
                    selectedClass + "," + SEAT_ROW + "B"
                  ),
                }}
                onClick={() =>
                  handleSeatClick(
                    SEAT_ROW + "B",
                    currentPassengerIndex,
                    classSeatAvailableList
                  )
                }
              >
                {getPassengerIndex(selectedClass + "," + SEAT_ROW + "B")}
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

const RowIndex = () => {
  return (
    <Grid container justifyContent={"center"} id="grid">
      <Grid item style={{ width: "32px", height: "32px", marginRight: "10px" }}>
        {" "}
      </Grid>
      <Grid
        item
        style={{
          width: "32px",
          height: "32px",
          textAlign: "center",
          marginRight: "8px",
          marginLeft: "8px",
          fontWeight: 500,
        }}
      >
        A
      </Grid>
      <Grid item style={{ width: "22px", height: "32px" }}>
        {" "}
      </Grid>
      <Grid
        item
        style={{
          width: "32px",
          height: "32px",
          textAlign: "center",
          marginRight: "8px",
          marginLeft: "8px",
          fontWeight: 500,
        }}
      >
        B
      </Grid>
    </Grid>
  );
};

export default OneByOne;
