import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Paper, Typography } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Loader } from "../index";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const baseURL = process.env.REACT_APP_BASEURL;

export const handleChangeSeat = (
  passengerSeatInfo,
  navigate,
  setLoading,
  passengerInfo,
  showErrorPopUp,
  errorPopUp,
  doNavigate = true
) => {
  setLoading(true);
  const enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
  const BASE_URL = baseURL;
  const URL = BASE_URL + "/kai/train/seat/map";

  let body = {
    enrollmentId: enrollmentId,
    tripId: passengerInfo.tripId,
    wagonClassId: passengerInfo.wagonClassId,
    subClass: passengerInfo.subClass,
    originId: passengerInfo.originId,
    destinationId: passengerInfo.destinationid,
  };
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Clientid: "Kai-Enrollment",
    },
  };

  axios
    .post(URL, body, headers)
    .then((res) => {
      setLoading(false);
      if (res.data?.success) {
        const data = res.data;
        if (data.classSeatData.length) {
          const list = data.classSeatData;
          const currentSeatClass = passengerSeatInfo.stamformdetcode;
          const currentSeat =
            passengerSeatInfo.wagondetrow + passengerSeatInfo.wagondetcol;
          for (let i = 0; i < list.length; i++) {
            let classname = Object.keys(list[i])[0];
            if (classname === currentSeatClass) {
              list[i][classname].classSeatAvailable.push(currentSeat);
            }
          }
        }

        let KAI = JSON.parse(localStorage.getItem("KAI"));
        KAI.seatList = {
          currentSeat: passengerSeatInfo,
          list: data,
          paxes: KAI.ticketBookingResponse.data.paxes,
          newSelectedSeat: KAI?.seatList?.newSelectedSeat || {},
        };
        localStorage.setItem("KAI", JSON.stringify(KAI));
        if (doNavigate) {
          navigate("/pilihkursi");
        }
      } else {
        showErrorPopUp({
          ...errorPopUp,
          showPopUp: true,
          message: res.message,
        });
      }
    })
    .catch((error) => {
      setLoading(false);
      showErrorPopUp({
        ...errorPopUp,
        showPopUp: true,
        message: error.message,
      });
    });
};

const SubmitedPassengerInfo = ({ passengerInfoList }) => {
  const navigate = useNavigate();
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState([]);
  const [passengerInfo, setPassengerInfo] = useState({});

  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    KAI.seatList = {};
    localStorage.setItem("KAI", JSON.stringify(KAI));
  }, []);

  useEffect(() => {
    if (passengerInfoList?.paxes?.length) {
      setList([...passengerInfoList.paxes]);
      setPassengerInfo(passengerInfoList);
    }
  }, [passengerInfoList]);

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ marginTop: "24px" }}>
        <Typography
          children={"Data Penumpang"}
          className={"inputSectionLabels"}
        />
      </div>

      {list.map(
        (
          {
            name,
            ticketnum,
            wagondetrow,
            wagondetcol,
            stamformdetcode,
            passengertype,
            id,
          },
          i
        ) => (
          <div key={i} style={{ marginTop: "16px" }}>
            <Paper
              style={{
                padding: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className={"dropShadow"}
            >
              <div>
                <Typography
                  id="passengerSubmited-name"
                  children={name}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A" /*, textTransform:"capitalize"*/,
                  }}
                />
                <Typography
                  id="passengerSubmited-passengerID-numberID"
                  children={`Kode Tiket - ${ticketnum}`}
                  style={{
                    fontSize: "12px",
                    color:
                      "#666" /*, display: passengertype == "A" ? "" : "none"*/,
                  }}
                />
                <Typography
                  id="passengerSubmited-seat"
                  children={
                    passengertype !== "A"
                      ? "Bayi"
                      : `${wagondetrow}${wagondetcol} / ${stamformdetcode}`
                  }
                  style={{ fontSize: "12px", color: "#666" }}
                />
              </div>
              <div style={{ display: passengertype !== "A" ? "none" : "" }}>
                <Button
                  id="passenger-GantiKursi"
                  children={"Ganti Kursi"}
                  variant={"outlined"}
                  endIcon={
                    <ArrowForwardIosRoundedIcon style={{ fontSize: "12px" }} />
                  }
                  style={{ padding: "5px 10px", fontWeight: "bold" }}
                  onClick={() => {
                    handleChangeSeat(
                      list[i],
                      navigate,
                      setLoading,
                      passengerInfo,
                      showErrorPopUp,
                      errorPopUp
                    );
                  }}
                />
              </div>
            </Paper>
          </div>
        )
      )}
    </div>
  );
};

export default SubmitedPassengerInfo;
